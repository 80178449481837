import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BsClockHistory, BsClock, BsPeople } from "react-icons/bs"
import Layout from "../../components/Layout"
import slugify from "slugify"
import deChar from "../../utils/deChar"
import RecomIdeetipp from "../../components/RecomIdeetipp"
import SEO from "../../components/SEO"
import Markdown from "react-markdown"

const IdeetippTemplate = ({ data }) => {
  const {
    title,
    prepTime,
    durationTime,
    content,
    people,
    rating,
    description: { description },
    image,
    link,
    notice,
  } = data.contentfulIdeetipp

  const getstarRating = starRating => {
    let content = []
    for (let i = 0; i < rating; i++) {
      content.push(<span className="fa fa-star checked"></span>)
    }
    return content
  }

  const pathToImage = getImage(image)
  const { tags, instructions, ingredients, tools } = content
  /*const description_html = React.render(
    <Markdown children={description} />,
    document.getElementById("root")
  )*/

  return (
    <Layout>
      <SEO title={title} description={description} />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />

      <main className="page">
        <div className="recipe-page">
          {/* hero */}
          <section className="recipe-hero">
            <article className="recipe-info">
              <GatsbyImage
                image={pathToImage}
                className="about-img"
                alt={title}
              />
            </article>
            <article className="recipe-info">
              <h1>{title}</h1>

              <p>
                <Markdown children={description} />
              </p>
              {/*icons*/}
              <div className="recipe-icons">
                <article>
                  <BsClock />
                  <h5>Vorbereitungszeit</h5>
                  <p>{prepTime}</p>
                </article>
                <article>
                  <BsClockHistory />
                  <h5>Gesamtdauer</h5>
                  <p>{durationTime}</p>
                </article>
                <article>
                  <BsPeople />
                  <h5>Personen</h5>
                  <p>{people}</p>
                </article>
              </div>
              {/*tags*/}
              <p className="recipe-tags">
                Tags :
                {tags.map((tag, index) => {
                  const deDE = deChar(tag)
                  const slug = slugify(deDE, { lower: true })

                  return (
                    <Link to={`/tags/${slug}`} key={index}>
                      {tag}
                    </Link>
                  )
                })}
              </p>
            </article>
          </section>
          {/* Button */}
          <article className="recipe-content">
            <a href={link} target="_blank" rel="noopener noreferrer sponsored">
              <button className="btn-aff">Jetzt entdecken</button>
            </a>
          </article>
          {/* rest of content */}
          <section className="recipe-content">
            <article>
              <h4>Anleitung</h4>
              {instructions.map((item, index) => {
                return (
                  <div key={index} className="single-instruction">
                    <header>
                      <p>{index + 1}.</p>
                      <div></div>
                    </header>
                    <p>{item}</p>
                  </div>
                )
              })}
              <h4>Hinweis</h4>
              <div className="small-notice">{notice}</div>
            </article>
            <article className="second-column">
              <div>
                <h4>Bewertung</h4>
                {getstarRating()} ({rating} Sterne)
              </div>

              <div>
                <h4>Zutaten</h4>
                {ingredients.map((item, index) => {
                  return (
                    <p key={index} className="single-ingredient">
                      {item}
                    </p>
                  )
                })}
              </div>
              <div>
                <h4>Werkzeuge</h4>
                {tools.map((item, index) => {
                  return (
                    <p key={index} className="single-ingredient">
                      {item}
                    </p>
                  )
                })}
              </div>
            </article>
          </section>
          <section className="featured-recipes">
            <h5>Entdecke weitere, interessante Tipps und Ideen</h5>
            <RecomIdeetipp />
          </section>
        </div>
      </main>
    </Layout>
  )
}
export const data = graphql`
  query getSingle($title: String) {
    contentfulIdeetipp(title: { eq: $title }) {
      id
      title
      content {
        tools
        id
        ingredients
        instructions
        tags
      }
      description {
        description
      }
      prepTime
      durationTime
      link
      people
      rating
      subtitle
      notice
      featured
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`

export default IdeetippTemplate
