import React from "react"
import TagsList from "./TagsList"
import IdeetippList from "./IdeetippList"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allContentfulIdeetipp(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 4
    ) {
      nodes {
        id
        title
        createdAt
        subtitle
        durationTime
        prepTime
        content {
          tags
        }
        image {
          id
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  }
`

const RecomIdeetipp = () => {
  const data = useStaticQuery(query)
  const ideetipp = data.allContentfulIdeetipp.nodes
  return (
    <section className="recipes-container">
      <IdeetippList ideetipp={ideetipp} />
    </section>
  )
}

export default RecomIdeetipp
